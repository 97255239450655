var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("video-player", {
        ref: "videoPlayer",
        staticClass: "vjs-big-play-centered",
        attrs: {
          options: {
            nativeControlsForTouch: true,
            sources: [
              {
                type: _vm.video.mime,
                src: _vm.video.playlist,
                withCredentials: _vm.withCredentials,
              },
            ],
            aspectRatio: "16:9",
            poster: _vm.video.poster,
            preload: "none",
            fluid: true,
            autoplay: _vm.autoplay,
            controls: _vm.controls,
            playbackRates: [0.25, 0.5, 1.0, 1.25, 1.5, 2.0],
            playsinline: _vm.playsinline,
            loop: _vm.loop,
          },
        },
        on: {
          ready: _vm.playerReadied,
          pause: _vm.saveVideoTime,
          play: _vm.videoPlayHandler,
          ended: _vm.onPlayerEnded,
        },
      }),
      _vm.video.markers && _vm.video.markers.length
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "CAccordeon",
                { attrs: { "accordion-data": [{ id: 0, theme: "Tаймкоды" }] } },
                [
                  _vm.video.markers && _vm.video.markers.length
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column timeCodes" },
                        _vm._l(_vm.video.markers, function (item, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              {
                                staticClass: "timecodeLink",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.player.currentTime(item.time)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.timeFormatter(+item.time)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", [_vm._v("- " + _vm._s(item.text))]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }