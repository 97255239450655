<template>
  <div>
    <video-player
        class="vjs-big-play-centered"
        :options="{
          nativeControlsForTouch: true,
          sources: [{type: video.mime, src: video.playlist, withCredentials}],
          aspectRatio: '16:9',
          poster: video.poster,
          preload: 'none',
          fluid: true,
          autoplay,
          controls,
          playbackRates: [0.25, 0.5, 1.0, 1.25, 1.5, 2.0],
          playsinline,
          loop,
          }"
        ref="videoPlayer"
        @ready="playerReadied"
        @pause="saveVideoTime"
        @play="videoPlayHandler"
        @ended="onPlayerEnded"
      />
    <div v-if="video.markers && video.markers.length" class="mt-3">
    <CAccordeon :accordion-data="[{id: 0, theme:'Tаймкоды'}]">
      <div class="d-flex flex-column timeCodes" v-if="video.markers && video.markers.length">
          <span
          v-for="(item,index) in video.markers"
          :key="index">
            <span class="timecodeLink" @click.stop="player.currentTime(item.time)">
              {{timeFormatter(+item.time)}}
            </span>
            <span>- {{item.text}}</span>
          </span>
        </div>
    </CAccordeon>
    </div>
  </div>
</template>

<script>
import { secToHHMMSS } from '@/assets/js/timeCode'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls';
import {videoPlayer} from 'vue-video-player-videojs-7'
import store from '@/store';
import CAccordeon from "@/components/training/LessonAccordionComponent"
import "videojs-hotkeys"
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'
// import addPlugin from '@/assets/js/plugins/videojsPlugins/videojs-markers.js'

export default {
  name: "VideoPlayer",
  props: {
    types: {
      type: Array,
      default: () => ([])
    },
    video: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: [Number, String],
      default: ""
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    saveTime:{
      type: Boolean,
      default: false,
    },
    lessonId: {
      type: [Number, String]
    },
    loop: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    videoPlayer,
    CAccordeon
  },
  data(){
    return {
      BUILD_PUBLIC_PATH: BUILD_PUBLIC_PATH,
      isReplaced: false,
      withCredentials: false,
    }
  },
  mounted(){
    window.onunload = this.saveVideoTime

    if(videojs?.browser?.IS_SAFARI){
      videojs.options = videojs.options || {}
      videojs.options.vhs = videojs.options.vhs || {}
      videojs.options.html5 = videojs.options.html5 || {}
      videojs.options.vhs.overrideNative = true
      videojs.options.html5.nativeAudioTracks = false
      videojs.options.html5.nativeVideoTracks = false
    }
    if(this.video.id){
      store.state.h[this.video.id] = this.h
    }
    if(videojs.Vhs && videojs.Vhs.xhr && !store.state.h.isSetVideojsBeforeRequest){
      store.state.h.isSetVideojsBeforeRequest = true
      videojs.Vhs.xhr.beforeRequest = (options) => {
        if (!options.uri.startsWith('key://') && !options.uri.endsWith('/key')){
          options.headers = options.headers || {};
          options.headers['Authorization'] = `Bearer ${store.state.user.accessToken}`
          /*options.beforeSend = function (xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${store.state.user.accessToken}`)
          }*/
          return options
        }
        const id = options.uri.replace(/.+\/([^\/]+)\/key/, '$1')
        if(!store.state.h[id]){
          return options
        }
        const hashKeyLength = store.state.h[id].length
        const buf = new ArrayBuffer(hashKeyLength);
        const bufView = new Uint8Array(buf);
        for (let i = 0; i < hashKeyLength; i++) {
          bufView[i] = store.state.h[id].charCodeAt(i);
        }
        options.xhr = () => {}
        options.xhr.status = 200
        options.xhr.open = () => {}
        options.xhr.abort = () => {}
        options.xhr.addEventListener = () => {}
        options.xhr.send = function () {
          setTimeout( () => {
            this.onload.apply(this)
          }, 0)
        }
        options.xhr.response = buf;
        return options
      }
    }
  },
  beforeDestroy() {
    this.saveVideoTime()
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    h() {
      const hash = this.video.h || this.video.hash
      if (hash.length < 128) {
        return hash
      }
      let h = ''
      const types = this.types.length ? this.types : this.video.types
      if(!types || types.includes('private')){
        h = hash.substr(7, 7)
        h += hash.substr(37, 5)
        h += hash.substr(133)
      }else{
        h = hash.substr(5, 6)
        h += hash.substr(69, 6)
        h += hash.substr(171)
      }
      return h
    }
  },
  methods: {
    videoPlayHandler(){
      this.setCurrentVideoTime()
      const players = videojs.getPlayers()
      for(const item in players){
        if(item !== this.player.id()){
          players[item]?.pause()
        }
      }
    },
    onPlayerEnded(){
      const videosCurrentTime = JSON.parse(localStorage.getItem('videosCurrentTime'))
      if(videosCurrentTime){
        for(const item in videosCurrentTime){
          if(this.video.id){
            if(item.includes(this.video.id))delete videosCurrentTime[item]
          }
        }
        localStorage.setItem('videosCurrentTime', JSON.stringify(videosCurrentTime))
      }
    },
    timeFormatter(time){
      return secToHHMMSS(time)
    },
    setCurrentVideoTime() {
      const videosCurrentTime = JSON.parse(localStorage.getItem('videosCurrentTime'))
      if(this.video.id){
        if (this.saveTime && videosCurrentTime && videosCurrentTime[`${this.video.id}-${this.lessonId}`]) {
          this.player.currentTime(videosCurrentTime[`${this.video.id}-${this.lessonId}`])
        }
      }
    },
    saveVideoTime() {
      if (this.saveTime) {
        const videosCurrentTime = JSON.parse(localStorage.getItem('videosCurrentTime'))
        if(this.video.id){
          if (!videosCurrentTime) {
            localStorage.setItem('videosCurrentTime', JSON.stringify({[`${this.video.id}-${this.lessonId}`]: this.player.currentTime()}))
          } else {
            videosCurrentTime[`${this.video.id}-${this.lessonId}`] = this.player.currentTime()
            localStorage.setItem('videosCurrentTime', JSON.stringify(videosCurrentTime))
          }
        }
      }
    },
    playerReadied(player) {
      const videoEl = player.el().getElementsByTagName('video')
      if(videoEl[0]){
        videoEl[0].poster = this.video.poster
      }
      if(this.video.id){
        store.state.h[this.video.id] = this.h
      }
      player.markers({
        markerStyle: {
          'width': '7px',
          'border-radius': '30%',
          'background-color': '#3686F6'
        },
        markers: this.video.markers
      });
      player.hotkeys({
        volumeStep: 0.1,
        seekStep: 10,
        enableModifiersForNumbers: false
      });
      const playerTech = player.tech({
        IWillNotUseThisInPlugins: true
      })
      if( /*!this.isReplaced
        &&*/ !playerTech.vhs
      ){
        this.isReplaced = true
        if(videojs.options){
          if(videojs.options.vhs && videojs.options.vhs.overrideNative !== undefined){
            delete videojs.options.vhs.overrideNative
          }
          if(videojs.options.html5){
            if(videojs.options.html5.nativeVideoTracks !== undefined){
              delete videojs.options.html5.nativeVideoTracks
            }
            if(videojs.options.html5.nativeAudioTracks !== undefined){
              delete videojs.options.html5.nativeAudioTracks
            }
          }
        }
        if(videojs.Vhs && videojs.Vhs.xhr) {
          videojs.Vhs.xhr.beforeRequest = (options) => options
        }
        const d = location.hostname.split('.').reverse()
        let domain = ''
        if(d.length > 1){
          domain = `domain=.${d[1]}.${d[0]};`
        }
        document.cookie = `Authorization=Bearer ${store.state.user.accessToken};${domain}path=/`;
        this.withCredentials = true
        if(this.video.id){
          this.video.playlist = `${process.env.VUE_APP_BACK_URL}/api/v1/videos/playlist-key/${this.video.id}?h=` + (this.video.hash || this.video.h)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import './videojs.markers.css';

.video-player {
  .video-js {
    &:hover {
      .vjs-big-play-button {
        background: transparent;

        &::after {
          content: url('../../assets/img/play-hovered.svg');
        }
      }
    }
  }

  .vjs-big-play-button {
    height: fit-content;
    .vjs-icon-placeholder {
      &::before {
        display: none;
      }
    }

    background: transparent;
    border: none;

    &::after {
      content: url('../../assets/img/play.svg');
    }
  }
}
.timecodeLink{
  color: var(--primary);
  cursor: pointer;
}

.timeCodes {
  max-height: 320px;
  //overflow: auto;
  padding-right: 30px;
}

.accordion-study__answer {
  margin-right: -30px;
}
</style>
