// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!./videojs.markers.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/play-hovered.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/play.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".video-player .video-js:hover .vjs-big-play-button {\n  background: transparent;\n}\n.video-player .video-js:hover .vjs-big-play-button::after {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.video-player .vjs-big-play-button {\n  height: -moz-fit-content;\n  height: fit-content;\n  background: transparent;\n  border: none;\n}\n.video-player .vjs-big-play-button .vjs-icon-placeholder::before {\n    display: none;\n}\n.video-player .vjs-big-play-button::after {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.timecodeLink {\n  color: var(--primary);\n  cursor: pointer;\n}\n.timeCodes {\n  max-height: 320px;\n  padding-right: 30px;\n}\n.accordion-study__answer {\n  margin-right: -30px;\n}\n", ""]);
// Exports
module.exports = exports;
